<template>
  <div>
    <SubNavCardVue :buttons="buttons" />
  </div>
  <!-- <div class="mt-5">
    <div class="s-layout__index_header">
      <div class="btn-group" role="group" aria-label="Default button group">
        <button
          type="button"
          @click="$router.push({ name: 'purchases-index' })"
          :class="$route.name.includes('purchases-index') ? 'active' : ''"
          class="btn"
        >
          <i class="bi bi-clipboard-check-fill"></i>
          Achats 
        </button>

 
        <button
          type="button"
          @click="$router.push({ name: 'purchases-noticed-index' })"
          :class="$route.name.includes('purchases-noticed') ? 'active' : ''"
          class="btn"
        >
          <i class="bi bi-info-circle-fill"></i>
          Remarqué
        </button>

        <button
          type="button"
          @click="$router.push({ name: 'purchases-supplier-report' })"
          :class="
            $route.name.includes('purchases-supplier-report') ? 'active' : ''
          "
          class="btn"
        >
          <i class="bi bi-person-lines-fill"></i>
          Rapport Fournisseur
        </button>

        <button
          type="button"
          @click="$router.push({ name: 'purchases-create' })"
          :class="
            $route.name.includes('purchases-create') ||
            $route.name.includes('purchases-items-create')
              ? 'active'
              : ''
          "
          class="btn"
        >
          <i class="bi bi-bag-plus-fill"></i>
          Création d'achat
        </button>

        <button
          type="button"
          @click="$router.push({ name: 'purchases-items-search' })"
          :class="
            $route.name.includes('purchases-items-search') ? 'active' : ''
          "
          class="btn"
        >
          <i class="bi bi-filter-square-fill"></i>
          Recherche détailé
        </button>
      </div>
    </div>

    <br />
    <router-view></router-view>
  </div> -->
</template>
<script>
import SubNavCardVue from '../../components/cards/SubNavCard.vue';

export default {
  components: { SubNavCardVue },
  data() {
    return {
      buttons: [
        {
          label: 'Achats',
          icon: 'bi bi-clipboard-check-fill',
          route: 'purchases-index',
          isActive: () => this.$route.name.includes('purchases-index')
        },
        {
          label: 'Remarqué',
          icon: 'bi bi-info-circle-fill',
          route: 'purchases-noticed-index',
          isActive: () => this.$route.name.includes('purchases-noticed')
        },
        {
          label: 'Rapport Fournisseur',
          icon: 'bi bi-person-lines-fill',
          route: 'purchases-supplier-report',
          isActive: () => this.$route.name.includes('purchases-supplier-report')
        },
        {
          label: 'Création d\'achat',
          icon: 'bi bi-bag-plus-fill',
          route: 'purchases-create',
          isActive: () => this.$route.name.includes('purchases-create') || this.$route.name.includes('purchases-items-create')
        },
        {
          label: 'Recherche détaillée',
          icon: 'bi bi-filter-square-fill',
          route: 'purchases-items-search',
          isActive: () => this.$route.name.includes('purchases-items-search')
        }
      ]
    };
  }
};
</script>